import React, { createRef } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import InputFormik from "./InputFormik";
import styled from "styled-components";
import { Button } from "./SharedStyles";
import apiEndpoints from "../helpers/apiEndpoints";
import ReCAPTCHA from "react-google-recaptcha";

const Row = styled.div`
  margin: 25px 0 0;
`;

const sendForm = (url, data, setSubmitting) => {
  setSubmitting(false);

  fetch(url, {
    method: "POST",
    mode: "cors",
    redirect: "follow",
    headers: new Headers({
      "Content-Type": "application/json"
    }),
    body: JSON.stringify(data)
  })
    .then((response) => {})
    .catch((error) => {
      setSubmitting(true);
    });
};

const gRecaptchaRef = createRef();

const Newsletter = () => {
  const reCaptchaV2SiteKey = process.env.GATSBY_RECAPTCHA_V2_SITE_KEY;

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          source: "StackCorp - Webhook",
          mailing_list_id: 3
        }}
        validationSchema={Yup.object({
          email: Yup.string().email().required("Invalid email address")
        })}
        onSubmit={async (values, { setSubmitting }) => {
          const data = values;
          const captcha_response = await gRecaptchaRef.current.executeAsync();

          gRecaptchaRef.current.reset();

          data["captcha_response"] = captcha_response;

          await sendForm(
            `${apiEndpoints.API_URL}/api/stack_corp/webhooks/mailing_list_subscription`,
            data,
            setSubmitting
          );
        }}
      >
        <Form>
          <Row>
            <InputFormik
              fullWidth={true}
              placeholder="myname@email.com"
              name="email"
              type="email"
            />
          </Row>
            <Row>
              <ReCAPTCHA
                ref={gRecaptchaRef}
                size="invisible"
                theme="dark"
                sitekey={reCaptchaV2SiteKey}
              />
            </Row>
          <Row>
            <Button bold as="input" type="submit" value="Subscribe" />
          </Row>
        </Form>
      </Formik>
    </>
  );
};

export default Newsletter;
