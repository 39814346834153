import React from "react";
import styled from "styled-components";
import { Headline40 } from "../styles/Headings";
import { Body18 } from "../styles/BodyTypes";
import { white } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import Newsletter from "./Newsletter";

const Title = styled(Headline40)`
  margin: 0 0 14px;
  color: ${white};
  width: 300px;

  @media (${mediaMedium}) {
    width: 100%;
  }
`;

const FooterNewsletter = () => {
  return (
    <div>
      <Title>GET THE NEWSLETTER</Title>
      <Body18 theme={{ color: white, margins: "0 0 22px" }}>
        We send a newsletter out about once a month full of insider commerce
        tips.
      </Body18>
      <Newsletter />
    </div>
  );
};

export default FooterNewsletter;
